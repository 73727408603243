import { useState } from "react";

import api from "src/api";
import covidImg from "src/assets/images/sars-cov-2-1.png";
import type { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import NoData from "src/components/NoData";
import QueryWrapper from "src/components/QueryWrapper";
import { useCustomer } from "src/context/Customer";

import { renameAvgColumns } from "../util";
import CovidLocationsCard from "./CovidLocationsCard";
import CovidSummaryCard from "./CovidSummaryCard";

const CovidPage = () => {
  const { customer_name, show_risk_scores } = useCustomer();
  const { data, isLoading } = api.data.covidWastewater.useQuery({
    fields: [
      "sampling_location_name",
      "location_type",
      "sample_date",
      "effective_concentration",
    ],
  });
  const { data: avgData, isLoading: avgIsLoading } =
    api.data.covidNational.useQuery(
      { fields: ["target_name", "date", "effective_conc_copies_per_l_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  const [concentrationUnit, setConcentrationUnit] =
    useState<Concentration>("1/L");

  return (
    <div>
      <h1 className="text-h1">SARS-CoV-2 Command Center for {customer_name}</h1>
      <QueryWrapper data={data} isLoading={isLoading || avgIsLoading}>
        {data?.data?.length ? (
          <div className="space-y-5">
            <CovidSummaryCard
              data={combinedData}
              setConcentrationUnit={setConcentrationUnit}
              concentrationUnit={concentrationUnit}
            />
            {show_risk_scores && (
              <CovidLocationsCard
                data={data.data}
                concentrationUnit={concentrationUnit}
                setConcentrationUnit={setConcentrationUnit}
                enableRiskLevels={show_risk_scores}
              />
            )}
          </div>
        ) : (
          <NoData target="Covid" img={covidImg} />
        )}
      </QueryWrapper>
    </div>
  );
};

export default CovidPage;
