import { utcFormat } from "d3-time-format";

import type { IsoDate } from "~api/types/util";

import type { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import { dateFormatter } from "src/components/GraphElements/formatters";

import type { PCRDatum } from "./types";

export interface AvgDatum {
  date: IsoDate; // x
  effective_conc_copies_per_l_avg: number | null; // y
  target_name:
    | "sars-cov-2"
    | "InfA"
    | "InfB"
    | "InfH5"
    | "NoV_GI"
    | "NoV_GII"
    | "RSV";
}

// Accessors
export const getSampleDate = (d: PCRDatum) => new Date(d.sample_date);
export const getPrimerTargetLabel = (d: PCRDatum) =>
  d.primer_target?.replace("NoV_", "").replace("Inf", "Influenza ");

// Formatters
export const fmtDateLong = utcFormat("%b %d, %Y");

export const fmtUnits = (unit: Concentration) =>
  unit === "1/L" ? "copies/L" : "copies/mL";

export const xTickFormat = dateFormatter({
  byDay: utcFormat("%b %-d '%y"), // "Mar 9 '23"
  byMonth: utcFormat("%b '%y"), // "Mar '23"
});

// Allow average data to be merged with kit data so they can both be represented in the same chart
export const renameAvgColumns = ({ data }: { data: AvgDatum[] }) => ({
  data: data.map((d) => ({
    sample_date: d.date,
    primer_target: d.target_name,
    effective_concentration: d.effective_conc_copies_per_l_avg,
    sampling_location_name: "US National Average",
    location_type: "community" as const,
  })),
});
